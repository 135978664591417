//获取文章分类
import { request_util } from '@/service';
import axios from 'axios';
//请求评论数据
export function getCommentList(data) {
    return request_util.get({
        url: '/comment/',
        params: data
    });
}
//创建评论
export function createComment(data) {
    return axios.post(process.env.VUE_APP_BASE_URL + 'comment/', data);
}
//根据qq获取名字
export function getqqInfo(data) {
    return axios({
        method: 'get',
        url: `https://tenapi.cn/qqname/?qq=${data}`
    });
}
