import { getCommentList } from '@/service/comments';
const module = {
    namespaced: true,
    state() {
        return {
            commentList: [],
            allCount: 0
        };
    },
    actions: {
        async getDataList({ commit }, payload) {
            const res = await getCommentList(payload);
            commit('changeCommentList', res.data?.list ?? []);
            commit('changeAllcount', res.data?.allCount ?? 0);
        }
    },
    mutations: {
        changeCommentList(state, data) {
            state.commentList = data;
        },
        changeAllcount(state, data) {
            state.allCount = data;
        }
    }
};
export default module;
