import axios from 'axios';
export class wjjRequest {
    constructor(config) {
        this.instance = axios.create(config);
        this.interceptor = config.interceptor;
        //从config中取出的，实例特有的拦截器
        this.instance.interceptors.request.use(this.interceptor?.requestOnFulfilled, this.interceptor?.requestOnRejected);
        this.instance.interceptors.response.use(this.interceptor?.responseOnFulfilled, this.interceptor?.responseOnRejected);
    }
    //传入泛型，用户可以指定返回的Promise的值的类型
    request(config) {
        return new Promise((resolve, reject) => {
            //request指定第二个泛型为传入的泛型，可以指定request返回的Promise的值的类型
            this.instance.request(config).then(resolve, reject);
        });
    }
    get(config) {
        return this.request({ ...config, method: 'GET' });
    }
    post(config) {
        return this.request({ ...config, method: 'POST' });
    }
    delete(config) {
        return this.request({ ...config, method: 'DELETE' });
    }
    put(config) {
        return this.request({ ...config, method: 'PUT' });
    }
}
