import { formatUtcString } from '@/utils/data-formate';
//注册全局属性
export function registerProperty(app) {
    //用dayjs转换日期
    app.config.globalProperties.$filters = {
        formatTime(value) {
            return formatUtcString(value);
        }
    };
}
