import { request_util } from '@/service';
import axios from 'axios';
//请求文章
export function getArticleList(data) {
    return request_util.get({
        url: '/article/',
        params: data
    });
}
//文章增加访客
export function addArticleCount(id) {
    if (id)
        return request_util.put({
            url: `/article/addCount/${id}`
        });
}
//TODO:模糊查询文章接口
export function getArticleListWithoutShowLoading(title) {
    return axios.get(process.env.VUE_APP_BASE_URL + 'article?title=' + title);
}
